import React, { useContext, useState } from "react";

import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import ConfirmDialog from "../General/ConfirmDialog";
import Grid from "@mui/material/Grid";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import MyDataGrid from "../General/Pagination/MyDataGrid";
import Edit from "./Edit";
import Entry from "./Entry";
import Create from "./Create";
import dayjs from "dayjs";

export const periodLogs = [
  {
    label: "Current month",
    from: dayjs().startOf("month").startOf("day"),
    to: dayjs().endOf("month").endOf("day")
  },
  {
    label: "Last month",
    from: dayjs().subtract(1, "month").startOf("month").startOf("day"),
    to: dayjs().subtract(1, "month").endOf("month").endOf("day")
  }
];
const statusValues = [
  { id: 1, text: "Active" },
  { id: 0, text: "Inactive" },
];
const providerValues = [
  { id: "xtrf", text: "XTRF" },
  { id: "tms", text: "TMS" },
];

export default function List(props) {
  const { homeState, homeDispatch } = useContext(HomeAppContext);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openRemove, setOpenRemove] = React.useState(false);
  const [removeParams, setRemoveParams] = React.useState(false);
  const [currentEntry, setCurrentEntry] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);

  const isUser =
    homeState.userProfile.role === "Admin" ||
    homeState.userProfile.role === "PowerUser" ||
    homeState.userProfile.role === "User";

  const allowActions = isUser;

  const removePrompt = (params) => {
    console.log(params);
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Removing prompt", loading: true }
    });
    api
      .delete(`/api/ai-prompts/${params.id}`)
      .then((response) => {
        if (response.data) {
          homeDispatch({
            type: "NOTIFICATION",
            data: { message: "AI Prompt deleted successfuly", type: "success" }
          });
        }
        refreshItems();
      })
      .catch((error) => {
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  const tableOptions = {
    columns: [
      {
        name: "Name",
        description: "Prompt Name",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "createdBy.fullName",
        description: "Created By",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 2
      },
      {
        name: "Client",
        description: "Client",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "AIEngine",
        description: "AI Engine",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "CreatedAt",
        description: "Create Date",
        sortable: true,
        filter: {
          enabled: false
        },
        size: 1
      },
      {
        name: "Prompt Tag(s)",
        description: "Tags",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 3
      },
      {
        name: "Actions",
        description: "",
        sortable: false,
        filter: {
          enabled: false
        },
        size: 1
      }
    ],
    defaultSort: [{ name: "CreatedAt", order: "-" }],
    additionalFilters: [
      {
        name: "PromptName",
        description: "Prompt Name",
        filter: {
          enabled: true,
          name: "NameLike",
          comparison: "@=*",
          type: "text",
          value: "",
          defaultIndex: 0,
          placeholder: "Filter prompt name"
        },
        size: allowActions ? 2 : "auto"
      },
      {
        name: "EngineId",
        description: "Engine Name",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/ai-engines/dropdown",
            multiple: true,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 2
      },
      {
        name: "CreatedBy",
        description: "User",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/users/dropdown",
            multiple: false,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 2
      },
      {
        name: "Tags",
        description: "Tags",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/ai-prompts/tags/dropdown",
            multiple: false,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 2
      },
      //   {
      //     name: "Tags",
      //     description: "Tags",
      //     filter: {
      //       enabled: true,
      //       name: "NameLike",
      //       comparison: "@=*",
      //       type: "text",
      //       value: "",
      //       defaultIndex: 0,
      //       placeholder: "Filter prompt tag"
      //     },
      //     size: allowActions ? 2 : "auto"
      //   },
      {
        name: "Period",
        description: "Create Date",
        filter: {
          enabled: true,
          required: true,
          comparison: "==",
          type: "dateRange",
          defaultValue: 2,
          value: periodLogs,
          options: {
            addNullPeriod: true
          }
        },
        size: 4
      },
      {
        name: "Provider",
        description: "Client Provider",
        filter: {
          enabled: true,
          comparison: "==",
          type: "select",
          value: providerValues,
        },
        size: 2
      },
      {
        name: "XtrfClientId",
        description: "XTRF Clients",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/clients/xtrfclients",
            multiple: true,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 4
      },
      {
        name: "TmsClientId",
        description: "TMS Clients",
        filter: {
          enabled: true,
          comparison: "==",
          type: "autocomplete",
          options: {
            url: "/api/clients/tmsclients",
            multiple: true,
            liveOptions: false,
            checkbox: false
          }
        },
        size: 4
      },
      {
        name: "IsActive",
        description: "Active Status",
        filter: {
          enabled: true,
          comparison: "==",
          type: "select",
          value: statusValues,
        },
        size: 2
      }
    ]
  };

  React.useEffect(() => {
    api
      .get("/api/login/authorized")
      .then((r) => {
        homeDispatch({
          type: "UPDATE_CURRENT_LOCATION",
          data: "AI Prompts"
        });
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  }, [homeDispatch]);

  const handleClickCreate = (event) => {
    setOpenCreate(true);
  };

  const handleClickEdit = (event, entry) => {
    setCurrentEntry(entry);
    setOpenEdit(true);
  };

  const handleClickRemove = (event, entry) => {
    setCurrentEntry(entry);
    setRemoveParams({ id: entry.id });
    setOpenRemove(true);
  };

  const handleClickExecute = (event, entry) => {
    setCurrentEntry(entry);
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Executing AI prompt", loading: true }
    });
    api.post(`/api/ai-prompts/execute-prompt/${entry.id}`).then((r) => {
      homeDispatch({
        type: "NOTIFICATION",
        data: { message: "AI prompt executed successfuly", type: "success" }
      });
    });
    //.catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  const handleClickExport = (event, entry) => {
    setCurrentEntry(entry);
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Preparing CSV file", loading: true }
    });

    api
      .get(`/api/ai-prompts/export/${entry.id}`, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = entry.promptName + ".csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "CSV file has been downloaded successfully",
            type: "success"
          }
        });
      })
      .catch((error) => {
        console.error("Error downloading CSV:", error);
        errorManagement.formErrors(error, homeDispatch);
      });
  };

  const cancelRemove = () => {
    setRemoveParams(null);
  };

  const refreshItems = () => {
    setRefresh(refresh + 1);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={"space-between"}>
        {allowActions && (
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickCreate}
              startIcon={<AddBoxIcon />}
            >
              New AI Prompt
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <MyDataGrid
            options={tableOptions}
            url={"/api/ai-prompts"}
            refresh={refresh}
            label={"AI Prompts"}
            renderItem={(item, index) => (
              <Entry
                key={index}
                entry={item}
                allowActions={allowActions}
                isAdmin={isUser}
                onEdit={handleClickEdit}
                onRemove={handleClickRemove}
                onExecute={handleClickExecute}
                onExport={handleClickExport}
              />
            )}
          />
        </Grid>
      </Grid>
      {allowActions && (
        <React.Fragment>
          <Edit
            open={openEdit}
            setOpen={setOpenEdit}
            entry={currentEntry}
            onEdit={refreshItems}
          />
          <Create
            open={openCreate}
            setOpen={setOpenCreate}
            onCreate={refreshItems}
          />
          {currentEntry && (
            <ConfirmDialog
              open={openRemove}
              setOpen={setOpenRemove}
              title={"Remove " + currentEntry.promptName + "?"}
              message={
                'Are you sure that you want to remove the AI Prompt "' +
                currentEntry.promptName +
                '"' +
                "?"
              }
              onCloseSuccess={removePrompt}
              successParams={removeParams}
              onClose={cancelRemove}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
