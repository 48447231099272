import Form, { ClientDesignFormMode, UserFormMode } from "./Form";
import React, { useContext, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { HomeAppContext } from "../Home";
import Slide from "@mui/material/Slide";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Edit({ open, setOpen, entry, onEdit }) {
  const { homeDispatch } = useContext(HomeAppContext);

  const [internalEntry, setInternalEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState([]);
  const [designNeedsReviewed, setdesignNeedsReviewed] = useState(false);
  const [tagsFromApi, setTagsFromApi] = useState([]);
  const [familiesFromApi, setFamiliesFromApi] = useState([]);

  React.useEffect(() => {
    if (entry !== null && open) {
      setInternalEntry(entry);
      setErrors([]);
      setLoading(false);
      setSubmitted(false);
      // setdesignNeedsReviewed(true);
    } else {
      setInternalEntry(null);
    }
  }, [entry, open]);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
    setInternalEntry(null);
  };

  const handleReviewdesign = () => {
    const reviewDto = {
      clientDesignClients: internalEntry.clientDesignClients.map((e) => e.id),
      name: internalEntry.name,
      design: internalEntry.design,
      provider: internalEntry.provider,
    };
    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Reviewing Style Guide", loading: true }
    });
    api
      .post("/api/client-designs/review-client design", reviewDto)
      .then((r) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: "Reviewing Style Guide success",
            type: "success"
          }
        });
        setdesignNeedsReviewed(false);
      })
      .catch((error) => errorManagement.formErrors(error, homeDispatch));
  };

  const handleCloseSave = () => {
    const updatedesign = {
      id: internalEntry.id,
      clientDesignClients: internalEntry.clientDesignClients.map((e) => e.id),
      name: internalEntry.name,
      design: internalEntry.design,
      inputType: internalEntry.inputType,
      ocrOption: internalEntry.ocrOption,
      fileId: internalEntry.fileId,
      provider: internalEntry.provider,
    };
    console.log(updatedesign);

    homeDispatch({
      type: "NOTIFICATION",
      data: { message: "Updating " + internalEntry.designName, loading: true }
    });
    setSubmitted(true);
    setLoading(true);
    api
      .put(`/api/client-designs/${updatedesign.id}`, updatedesign)
      .then((response) => {
        homeDispatch({
          type: "NOTIFICATION",
          data: {
            message: internalEntry.designName + " updated",
            type: "success"
          }
        });
        setOpen(false);
        setLoading(false);
        onEdit();
      })
      .catch((error) => {
        setLoading(false);
        errorManagement.formErrors(error, homeDispatch, setErrors);
      });
  };

  return internalEntry === null ? null : (
    <Dialog
      maxWidth={"lg"}
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-edit-entry"
    >
      <DialogTitle id="alert-dialog-edit-entry">{"Edit Style Guide"}</DialogTitle>
      <DialogContent>
        <Form
          mode={ClientDesignFormMode.EDIT}
          entity={internalEntry}
          tags={tagsFromApi}
          engineFamilies={familiesFromApi}
          errors={errors}
          setEntity={setInternalEntry}
          loading={loading}
          submitted={submitted}
          setSaveDisabled={setSaveDisabled}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        {designNeedsReviewed === true ? (
          <Button
            onClick={handleReviewdesign}
            variant="outlined"
            color="primary"
          >
            Review Style Guide
          </Button>
        ) : (
          <Button
            onClick={handleCloseSave}
            color="primary"
            variant="outlined"
            autoFocus
            disabled={loading || saveDisabled}
          >
            Save Style Guide
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
