import React, { useContext, useState, useRef, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import _ from "lodash";
import CustomAutocomplete from "../General/CustomAutocomplete";
import FileUploader from "../General/FileUploader";
import Slider from "@mui/material/Slider";
import Chip from "@mui/material/Chip";
import { HomeAppContext } from "../Home";
import api from "../../Services/api";
import { errorManagement } from "../../Services/errorManagement";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Grid, TextField, Button, Select, MenuItem, InputLabel, FormControl, Typography, CircularProgress, IconButton  } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

export const ClientDesignFormMode = {
  NEW: "new",
  EDIT: "edit"
};

export default function Form({
  mode = ClientDesignFormMode.NEW,
  entity = null,
  errors = [],
  tags = [],
  engineFamilies = [],
  setEntity,
  loading,
  submitted,
  setSaveDisabled
}) {
  const { homeDispatch } = useContext(HomeAppContext);
  const [variables, setVariables] = useState([]);
  const [inputType, setInputType] = useState(entity.inputType || "");
  const [ocrOption, setOcrOption] = useState(entity.ocrOption || "");
  const [file, setFile] = useState(entity.fileId ? [{ fileId: entity.fileId }] : 0);
  const [fileText, setFileText] = useState("");
  const textFieldRef = useRef(null);
  const [fileCountRemaining, setFileCountRemaining] = useState(1);
  const [isUploadButtonEnabled, setIsUploadButtonEnabled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [inputStatus, setInputStatus] = useState(false);
  const [fileDeletedStatus, setFileDeletedStatus] = useState(false);
  const [provider, setProvider] = useState(entity.provider || "");
  const [isClientDropdownDisabled, setIsClientDropdownDisabled] = useState(!entity.provider);

  const onProviderChange = (event) => {
    const selectedProvider = event.target.value;
    setProvider(selectedProvider);
    setIsClientDropdownDisabled(!selectedProvider);

    setEntity({ ...entity, provider: selectedProvider, clientDesignClients: [] });
  };

  const onFileUploaded = async (value) => {
    const uploadedFiles = value.map((item) => ({
      fileId: item.id,
      name: item.name
    }));
  
    if (uploadedFiles.length > 0) {
      setFile(uploadedFiles); 
      setEntity({ ...entity, fileId: uploadedFiles[0].fileId });
    }
    
    checkIfUploadCanBeEnabled(uploadedFiles, ocrOption);
  };

  const handleRemoveFile = () => {
    console.log("Removing file");
    setFile(0);
    setEntity({ ...entity, fileId: 0, fileName: null, ocrOption: "", design: "" });
    setFileText("");
    setFileDeletedStatus(true);
    setOcrOption("");
  };

  const handleInputTypeChange = (event) => {
    const newInputType = event.target.value;
    setInputType(newInputType);
    setEntity({ ...entity, inputType: event.target.value, design: "", ocrOption: "", fileId: 0 });
    setFileText("");
    setIsUploadButtonEnabled(false);

    if(entity.inputType === "text" && newInputType === "file") {
      setInputStatus(true);
      setOcrOption("");
    }

    if(entity.inputType === "file" && newInputType === "text") {
      setInputStatus(false);
    }
  };

  const handleOcrOptionChange = (event) => {
    const selectedOcrOption = event.target.value;
    setOcrOption(selectedOcrOption);
    setEntity({ ...entity, ocrOption: selectedOcrOption });
    checkIfUploadCanBeEnabled(file, selectedOcrOption);

    if (selectedOcrOption === "with-ocr") {
      alert("With OCR: The file will be scanned to pull text from images. This process may take a few minutes.");
    } else if (selectedOcrOption === "without-ocr") {
      alert("Without OCR: The file will be uploaded without scanning for text in images. This process may be faster.");
    }
  };

  const checkIfUploadCanBeEnabled = (uploadedFile, ocrOptionValue) => {
    if (uploadedFile && uploadedFile[0] && ocrOptionValue) {
      setIsUploadButtonEnabled(true);
    } else {
      setIsUploadButtonEnabled(false);
    }
  };

  const handleUploadFile = async () => {
    if (file && file[0]) {
      setUploading(true); 

      const params = {
        fileId: file[0].fileId,
        ocrOption: ocrOption,
      };

      try {
        const response = await api.get("/api/files/process-file", {
          params: params
        });

        if (response.data.count !== 0) {
          setFileText(response.data.text);
          setEntity({ ...entity, design: response.data.text })
        }

      } catch (error) {
        console.error("File upload failed:", error);
      } finally {
        setUploading(false);
    }
  };
}

  const onInputChange = (event, property) =>
    setEntity({ ...entity, [property]: event.target.value });

  const onDatasetChange = (value, property) => {
    if (value !== "") {
      setEntity({ ...entity, [property]: value });
    }
  };

  const onMultiInputChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onClientChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onTagInputChange = (value, property) => {
    setEntity({ ...entity, [property]: value });
  };

  const onTemperatureChange = (event, newValue) => {
    setEntity({ ...entity, temperature: newValue });
  };

  const onValueChange = (value, property) =>
    setEntity({ ...entity, [property]: value });

  const insertTextAtCursor = (text) => {
    const textField = textFieldRef.current;
    if (!textField) return;

    const startPos = textField.selectionStart;
    const endPos = textField.selectionEnd;

    const newValue =
      textField.value.substring(0, startPos) +
      text +
      textField.value.substring(endPos, textField.value.length);

    textField.value = newValue;
    setEntity({ ...entity, design: newValue });

    textField.selectionStart = startPos + text.length;
    textField.selectionEnd = startPos + text.length;
  };

  useEffect(() => {
    if (mode === ClientDesignFormMode.EDIT && entity.fileId) {
      async function fetchFile() {
        try {
          const responseFile = await api.get(`/api/files/get-file?id=${entity.fileId}`);
          if (responseFile.data) {
            setFile([{ fileId: responseFile.data.fileId, name: responseFile.data.name }]);
            setFileText(entity.design);
          }
        } catch (error) {
          console.error("Error fetching file: ", error);
        }
      }
      fetchFile();
    }
  }, [entity.fileId, mode]);

  return entity === null ? null : (
    <Grid container spacing={2}>
      {/* Left Side - Inputs */}
      <Grid item xs={6}>
      <FormControl fullWidth>
                <InputLabel id="provider-label">Provider</InputLabel>
                <Select
                  labelId="provider-label"
                  id="provider"
                  value={provider}
                  label="Provider"
                  onChange={onProviderChange}
                >
                  <MenuItem value="xtrf">XTRF</MenuItem>
                  <MenuItem value="tms">TMS</MenuItem>
                </Select>
              </FormControl>

      <CustomAutocomplete
                  value={entity.clientDesignClients}
                  setValue={(value) => onClientChange(value, "clientDesignClients")}
                  related={null}
                  onLoading={setSaveDisabled}
                  url={`/api/clients/clients?provider=${entity.provider}`}
                  label={"Clients"}
                  placeholder={"Select a client"}
                  noOptionsText={"No client found"}
                  loadingText={"Searching"}
                  margin={"normal"}
                  multiple={true}
                  liveOptions={true}
                  disabled={isClientDropdownDisabled}
                  error={_.isArray(errors.clientDesignClients)}
                  helperText={
                    _.isArray(errors.clientDesignClients) && errors.clientDesignClients.join()
                  }
                  disableClearable={true}
                />

        <TextField
          label="Style Guide Name"
          fullWidth
          value={entity.name || ""}
          onChange={(event) => setEntity({ ...entity, name: event.target.value })}
        />
        
        <FormControl fullWidth margin="normal">
          <InputLabel>Choose Input Type</InputLabel>
          <Select value={inputType} onChange={handleInputTypeChange}>
            <MenuItem value="text">Text Input</MenuItem>
            <MenuItem value="file">File Upload</MenuItem>
          </Select>
        </FormControl>

        {inputType === "text" ? (
          <TextField
            label="Style Guide Details"
            fullWidth
            multiline
            rows={9}
            value={entity.design || ""}
            onChange={(event) => setEntity({ ...entity, design: event.target.value })}
          />
        ) : inputType === "file" ? (
          <>
                      {/* Show File Information if File Exists */}
                      {mode === ClientDesignFormMode.EDIT && !inputStatus && !fileDeletedStatus && file && file[0].fileId > 0 ? (
              <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "16px",
                marginTop: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#f5f5f5",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#333" }}>
                  File ID: {file[0].fileId}
                </Typography>
                <Typography variant="body2" style={{ color: "#555" }}>
                  File Name: {file[0].name}
                </Typography>
              </div>

              <IconButton
                variant="contained"
                color="secondary"
                onClick={handleRemoveFile}
                style={{
                  backgroundColor: "#e57373",
                  color: "white",
                  marginLeft: "auto",
                  borderRadius: "50%",
                  boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            ) : (
              <>
            <FileUploader
              onFileUploaded={onFileUploaded}
              uploadUrl="/api/files"
              acceptedFileExtensions=".docx"
              acceptedFileExtensionsMessage={
                ".docx"
              }
              numberOfFilesCanBeUploaded={fileCountRemaining}
              maxFiles={fileCountRemaining}
              maxSize={536870912}
              existingFiles={file}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Choose OCR Option</InputLabel>
              <Select value={entity.ocrOption} onChange={handleOcrOptionChange}>
                <MenuItem value="with-ocr">With OCR</MenuItem>
                <MenuItem value="without-ocr">Without OCR</MenuItem>
              </Select>
            </FormControl>
            <Button
              onClick={handleUploadFile}
              variant="contained"
              color="primary"
              disabled={!isUploadButtonEnabled || uploading}
              startIcon={uploading ? <CircularProgress size={20} /> : null}
            >
              {uploading ? "Processing..." : "Process File"}
            </Button>
            </>
            )}
          </>
        ) : null}
      </Grid>

      {/* Right Side - Live Preview */}
      <Grid item xs={6}>
        <Typography variant="h6">Live Preview</Typography>
        <Typography variant="body1"       
        sx={{
        whiteSpace: "pre-wrap",        
        wordWrap: "break-word",        
        padding: "10px",               
        maxWidth: "100%",              
        fontFamily: "Arial, sans-serif",
        fontSize: "14px",              
        lineHeight: "1.5",             
        border: "1px solid #ccc",      
        overflowY: "auto",             
        height: "400px",               
        boxSizing: "border-box",       
        }}
        >{inputType === "text" ? entity.design : fileText}</Typography>
      </Grid>
    </Grid>
  );
}
