import { Route, Switch } from "react-router-dom";

import AdHocNotifications from "./Admin/System/AdHocNotifications/List";
import Login from "./Login";
import Logout from "./Logout";
import NotificationsList from "./Tests/Notifications/List";
import React from "react";
import RedirectPage from "./RedirectPage";
import SystemMailLogs from "./Admin/System/MailLogs/List";
import SystemNotifications from "./Admin/System/Notifications/List";
import SystemSettings from "./Admin/System/Settings/SystemSettings";
import UserList from "./Admin/Users/List";
import UserResetPassword from "./User/UserResetPassword";
import Blank from "./Blank";
import LogList from "./Logs/List";
import ListAIEngines from "./AIEngine/List";
import ListAIPrompts from "./AIPrompt/List";
import ListDatasets from "./Dataset/List";
import ListAIAssistants from "./AIAssistant/List";
import PromptExecutionHistoryList from "./PromptExecutionHistory/List"
import AIEngineGroupList from "./AIEngineGroup/List"
import ClientDescriptionList from "./ClientDescription/List"
import ClientDesignList from "./ClientDesign/List"

const RenderAdmin = () => (
  <Switch>
    <Route path="/redirect" component={RedirectPage} />
    <Route path="/login" component={Login} />
    <Route path="/resetPassword" component={UserResetPassword} />
    <Route path="/logout" component={Logout} />
    <Route path="/users" component={UserList} />
    <Route path="/ai-engines" component={ListAIEngines} />
    <Route path="/aienginegroup" component={AIEngineGroupList}/>
    <Route path="/ai-assistants" component={ListAIAssistants} />
    <Route path="/ai-prompts" component={ListAIPrompts} />
    <Route path="/datasets" component={ListDatasets} />
    <Route path="/client-descriptions" component={ClientDescriptionList} />
    <Route path="/client-designs" component={ClientDesignList} />
    <Route path="/promptexecutionhistory" component={PromptExecutionHistoryList}/>
    <Route path="/system/settings" component={SystemSettings} />
    <Route path="/system/notifications" component={SystemNotifications} />
    <Route path="/system/mail-logs" component={SystemMailLogs} />
    <Route path="/system/ad-hoc-notifications" component={AdHocNotifications} />
    <Route path="/tests/notifications" component={NotificationsList} />
    <Route path="/logs" component={LogList} />
    <Route component={RedirectPage} />
  </Switch>
);
const RenderPowerUser = () => (
  <Switch>
    <Route path="/redirect" component={RedirectPage} />
    <Route path="/login" component={Login} />
    <Route path="/resetPassword" component={UserResetPassword} />
    <Route path="/logout" component={Logout} />
    <Route path="/users" component={UserList} />
    <Route path="/system/settings" component={SystemSettings} />
    <Route path="/system/notifications" component={SystemNotifications} />
    <Route path="/system/mail-logs" component={SystemMailLogs} />
    <Route path="/system/ad-hoc-notifications" component={AdHocNotifications} />
    <Route path="/tests/notifications" component={NotificationsList} />
    <Route path="/logs" component={LogList} />
    <Route path="/ai-assistants" component={ListAIAssistants} />
    <Route path="/ai-prompts" component={ListAIPrompts} />
    <Route component={RedirectPage} />
  </Switch>
);

const RenderUser = () => (
  <Switch>
    <Route path="/blank" component={Blank} />
    <Route path="/redirect" component={RedirectPage} />
    <Route path="/login" component={Login} />
    <Route path="/resetPassword" component={UserResetPassword} />
    <Route path="/logout" component={Logout} />
    <Route path="/users" component={UserList} />
    <Route path="/ai-assistants" component={ListAIAssistants} />
    <Route path="/ai-prompts" component={ListAIPrompts} />
    <Route component={RedirectPage} />
  </Switch>
);

export default function RenderRouter({ userProfile }) {
  return userProfile === null ? (
    <Switch>
      <Route path="/redirect" component={RedirectPage} />
      <Route path="/login" component={Login} />
      <Route path="/resetPassword" component={UserResetPassword} />
      <Route component={RedirectPage} />
    </Switch>
  ) : (
    {
      Admin: <RenderAdmin />,
      PowerUser: <RenderPowerUser />,
      User: <RenderUser />
    }[userProfile.role]
  );
}
